.Footer_container{
    position: relative;
}
.Footer_container>hr{
    border: 1px solid var(--gray);
}
.footer{
    padding: 1rem 2rem;
    display: flex;
    flex-direction: column;
    gap: 4rem;
    align-items: center;
    justify-content: center;
    height: 20rem;
}
.socials{
    display: flex;
    gap: 4rem;
}
.socials>a>img{
    width: 2rem;
    height: 2rem;
    /* cursor: pointer; */
}
.logo-f>img{
    width: 10rem;

}
/* ==============FOOTER BLUR=============== */
.footer-blur1{
    bottom: 0;
    right: 10%;
    width: 26rem;
    height: 12rem;
    filter: blur(200px);
    background:rgb(255,70,0);

}
.footer-blur2{
    bottom: 0;
    left: 15%;
    width: 26rem;
    height: 12rem;
    filter: blur(200px);
    background: rgb(255,115,0);

}